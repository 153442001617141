<template>
  <b-card>
    <h4>Customer Resources</h4>
    <b-row class="justify-content-center">
      <b-col
        md="6"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="top-btn">
          <b-button
            :href="getTrackingLink()"
            target="_blank"
            class="custom-button"
            variant="warning"
            style="margin-bottom:1px;"
          >
            Tracking Page
          </b-button>
        </div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.bottom.v-primary="'URL link can be copied by clicking the copy link icon!'"
          variant="outline-none"
          class="custom-button"
          style="margin-top: 10px;"
          @click="copyTrackingURL"
        >
          <feather-icon icon="LinkIcon" />
          Copy URL
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import {
  tenant,
} from '@/constants/config'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [SuccessMessage, ErrorMessage],

  data() {
    return {
      tenant,
    }
  },

  methods: {
    copyTrackingURL() {
      try {
        const urlText = this.getTrackingLink()
        const textarea = document.createElement('textarea')
        textarea.value = urlText
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        this.showSuccessMessage('Link has been copied successfully')
      } catch (error) {
        this.showErrorMessage('Failed to copy link')
      }
    },
    getTrackingLink() {
      if (this.appEnv === 'local') {
        return 'http://localhost:3000'
      }
      if (this.appEnv === 'staging' || window.location.origin.split('.')[1] === 'office') {
        return window.location.origin.replaceAll('office', 'tracking')
      }

      // if url inlude curfox return below one
      if (window.location.host.includes('curfox')) {
        return `https://${tenant}.tracking.curfox.com`
        // eslint-disable-next-line no-else-return
      } else {
        // else replace office/merchant with tracking
        return window.location.origin.replaceAll('office', 'tracking')
      }
    },
  },
}

</script>

<style>
.custom-button {
    margin-top: 20px;
    margin-left: 60px;
}
</style>
